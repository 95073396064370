import React from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import detailsData from "../data/details.json";
import "../styles/Threads.css";

const ThreadsPage = () => {
  const { categoryId } = useParams();
  const threads = detailsData.details[categoryId] || [];

  return (
    <div className="threads-container">
      <Breadcrumb categoryId={categoryId} />
      <h1>Bài viết</h1>
      <div className="threads">
        {threads.map((thread) => (
          <div key={thread.id} className="thread-item">
            <div className="thread-avatar">
              <img src={`${thread.authorAvatar}`} alt="Thread Avatar" />
            </div>
            <div className="thread-content">
              <h3>
                <Link to={`/categories/${categoryId}/threads/${thread.id}`}>
                  {thread.title}
                </Link>
              </h3>
              <div className="thread-meta">
                Bởi {thread.author} • {thread.date}
                <br />
                Trả lời: {thread.replies} • Xem: {thread.views}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThreadsPage;
