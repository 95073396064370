import React from "react";
import { Box, Typography, Link } from "@mui/material";
import "../styles/Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box className="footer-container">
      {/* Logo */}
      {/* <Typography className="footer-logo">9Bricks</Typography> */}

      {/* Links */}
      <Box className="footer-links">
        <Box className="footer-section">
          <Link href="/noi-quy-chung" className="footer-link">
            Nội quy chung
          </Link>
        </Box>
        <Box className="footer-section">
          <Link
            href="/thoa-thuan-cung-cap-su-dung-dich-vu"
            className="footer-link"
          >
            Thỏa thuận sử dụng dịch vụ
          </Link>
        </Box>
        <Box className="footer-section">
          <Link href="#" className="footer-link">
            Hỗ trợ, hướng dẫn
          </Link>
        </Box>
        <Box className="footer-section">
          <Link
            href="https://9bricks.com"
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Liên hệ quảng cáo
          </Link>
        </Box>
      </Box>

      {/* Bottom Section */}
      <Box className="footer-bottom">
        <Typography className="footer-text-intro">
          Tin Công Nghệ & Đời Sống
        </Typography>
        <Box className="footer-bottom-links">
          <Link href="#" className="footer-link">
            Gửi thư góp ý về địa chỉ
          </Link>
          <Link
            href="mailto:support@9Bricks.com"
            target="_newtab"
            className="footer-link"
          >
            support@9Bricks.com
          </Link>
        </Box>
        <Typography className="footer-text">
          CÔNG TY TNHH TƯ VẤN PHÁT TRIỂN PHẦN MỀM VÀ TRUYỀN THÔNG 9BRICKS
        </Typography>
        <Typography className="footer-text">
          {" "}
          ©Copyright {currentYear}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
