const endpoints = {
    auth: {
        login: '/auth/login',
        logout: '/auth/logout',
    },
    users: {
        register: '/users/register',
        getAll: '/users',
        getById: (id: string) => `/users/${id}`,
        updateRoles: (id: string) => `/users/${id}/roles`,
        profile: '/users/me',
        updateProfile: '/users/me',
        sendVerificationEmail: '/users/send-verification-email',
        verifyEmail: '/users/verify-email',
        getByEmail: (email: string) => `/users/${email}`,
    },
    roles: {
        create: '/roles',
        getAll: '/roles',
        getByName: (name: string) => `/roles/${name}`,
        updateByName: (name: string) => `/roles/${name}`,
        deleteByName: (name: string) => `/roles/${name}`,
    },
    email: {
        send: '/email/send',
    },
    categories: {
        list: '/categories',
        details: (id: string) => `/categories/${id}`,
        create: '/categories',
        update: (id: string) => `/categories/${id}`,
        delete: (id: string) => `/categories/${id}`,
    },
    news: {
        list: '/news',
        details: (id: string) => `/news/${id}`,
        create: '/news',
        update: (id: string) => `/news/${id}`,
        delete: (id: string) => `/news/${id}`,
        updateStatus: (id: string) => `/news/${id}/status`,
    },
    activities: {
        list: '/activities',
        details: (id: string) => `/activities/${id}`,
        count: '/activities/count',
        create: '/activities',
        update: (id: string) => `/activities/${id}`,
        delete: (id: string) => `/activities/${id}`,
    },
    shortNews: {
        list: '/short-news',
        details: (id: string) => `/short-news/${id}`,
        create: '/short-news',
        update: (id: string) => `/short-news/${id}`,
        delete: (id: string) => `/short-news/${id}`,
        updateStatus: (id: string) => `/short-news/${id}/status`,
    },
    notifications: {
        create: '/notifications',
        list: '/notifications',
        markAsRead: (id: string) => `/notifications/${id}/read`,
        unreadCount: '/notifications/unread-count',
    },
};

export default endpoints;
