import React, { useState, useEffect } from "react";
import "../styles/News.css";
import NewsMock from "../data/newsMock.json"; // Mock data

const News = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  // Thêm/loại bỏ class "modal-open" trên body khi modal được mở
  useEffect(() => {
    if (selectedItem) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open"); // Cleanup khi component unmount
    };
  }, [selectedItem]);

  const handleClose = () => {
    setSelectedItem(null); // Close the modal
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <div className="short-news-container">
      {/* Featured Article */}
      <div
        className="short-news-featured"
        onClick={() => setSelectedItem(NewsMock.news[0])} // Open modal for featured article
      >
        <img
          src={NewsMock.news[0].image}
          alt={NewsMock.news[0].title}
          className="short-news-featured-image"
        />
        <div className="short-news-featured-content">
          <span className="short-news-featured-category">
            {NewsMock.news[0].category}
          </span>
          <h2 className="short-news-featured-title">
            {NewsMock.news[0].title}
          </h2>
          <p className="short-news-featured-description">
            {truncateText(NewsMock.news[0].description, 120)} {/* Truncated */}
          </p>
          <p>
            <span className="short-news-featured-author">
              {NewsMock.news[0].author}
            </span>
            <span className="short-news-featured-meta">
              {NewsMock.news[0].date}
            </span>
          </p>
        </div>
      </div>

      {/* List of Articles */}
      <div className="short-news-list">
        {NewsMock.news.slice(1).map((newsItem) => (
          <div
            className="short-news-item"
            key={newsItem.id}
            onClick={() => setSelectedItem(newsItem)} // Open modal on click
          >
            <span className="short-news-item-category">
              {newsItem.category}
            </span>
            <div className="short-news-item-content">
              <h3 className="short-news-item-title">{newsItem.title}</h3>

              <p className="short-news-item-meta">
                {newsItem.author} • {newsItem.date}
              </p>
            </div>
            <img
              src={newsItem.image}
              alt={newsItem.title}
              className="short-news-item-image"
            />
          </div>
        ))}
      </div>

      {/* Modal for Selected Item */}
      {selectedItem && (
        <div
          className="news-modal"
          onClick={handleClose} // Close modal when clicking outside
        >
          <div
            className="news-modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
          >
            <button className="news-modal-close" onClick={handleClose}>
              &times;
            </button>
            <span className="news-modal-category">{selectedItem.category}</span>
            <img
              src={selectedItem.image}
              alt={selectedItem.title}
              className="news-modal-image"
            />
            <h2 className="news-modal-title">{selectedItem.title}</h2>
            <p className="news-modal-meta">
              {selectedItem.author} • {selectedItem.date}
            </p>
            <div className="news-modal-description-wrapper">
              <p className="news-modal-description">{selectedItem.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
