import React from "react";
import "../styles/ServiceAgreement.css";
import agreementData from "../data/serviceAgreement.json";

const ServiceAgreement = () => {
  return (
    <div className="service-agreement-container">
      <h1 className="service-agreement-title">{agreementData.title}</h1>
      {agreementData.sections.map((section, index) => (
        <section key={index} className="service-agreement-section">
          <h2 className="section-title">{section.title}</h2>
          {section.paragraphs.map((paragraph, idx) => (
            <p key={idx} className="service-agreement-paragraph">
              {paragraph}
            </p>
          ))}
          {section.list && (
            <ul className="service-agreement-list">
              {section.list.map((item, idy) => (
                <li key={idy}>{item}</li>
              ))}
            </ul>
          )}
        </section>
      ))}
      <footer className="service-agreement-footer">
        <p>
          Mọi thông tin chi tiết vui lòng liên hệ qua email:{" "}
          <a href="mailto:support@9bricks.com" className="email-link">
            support@9bricks.com
          </a>
        </p>
      </footer>
    </div>
  );
};

export default ServiceAgreement;
