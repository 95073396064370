import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/HighlightHeaderArticle.css";

const HighlightHeaderArticle = ({
  category,
  title,
  description,
  author,
  date,
  time,
  image,
  bgColor,
  textColor,
}) => {
  return (
    <Box
      className="highlight-header"
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {/* Image Section */}
      <Box
        className="header-image"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />

      {/* Content Section */}
      <Box className="header-content">
        {/* Category */}
        <Typography className="header-category">{category}</Typography>

        {/* Title */}
        <Typography className="header-title">{title}</Typography>

        {/* Description */}
        <Typography className="header-description">{description}</Typography>

        {/* Author and Date */}
        <Typography className="header-author">Người viết {author}</Typography>
        <Typography className="header-author">
          {date}, {time}
        </Typography>

        {/* Footer Icons */}
        <Box className="header-footer-icons">
          <Box className="icon">&#x1F517;</Box>
          <Box className="icon">&#x1F4F1;</Box>
          <Box className="icon">&#x1F4AC;</Box>
          <Typography className="comments">4 bình luận</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HighlightHeaderArticle;
