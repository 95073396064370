import React from "react";
import { Link } from "react-router-dom";
import tipsData from "../data/tipsAndTricks.json";
import "../styles/TipsAndTricks.css";

const TipsAndTricks = () => (
  <div className="tips-container">
    <h1 className="tips-title">Tips & Tricks</h1>
    <div className="tips-grid">
      {tipsData.categories.map((tip) => (
        <div key={tip.id} className="tip-item">
          <img src={tip.image} alt={tip.title} className="tip-image" />
          <div className="tip-content">
            <h3>
              <Link to={`/tips-and-tricks/${tip.id}`} className="tip-link">
                {tip.title}
              </Link>
            </h3>
            <p className="tip-summary">{tip.summary}</p>
            <p className="tip-meta">
              By {tip.author} • {tip.date}
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default TipsAndTricks;
