import React from "react";
import { Link, useParams } from "react-router-dom";
import categoriesData from "../data/categories.json";

import "../styles/Breadcrumb.css";

const Breadcrumb = () => {
  const { categoryId, threadId } = useParams();
  const category = categoriesData.categories.find(
    (cat) => cat.id === parseInt(categoryId)
  );

  return (
    <div className="breadcrumb">
      {/* Root Link */}
      <Link to="/categories">Chuyên mục</Link>
      {/* Category Link */}
      {categoryId && (
        <>
          <span> &gt; </span>
          <Link to={`/categories/${categoryId}`}>{category?.name}</Link>
        </>
      )}
      {/* Thread Title */}
      {threadId && <span> &gt; Bài viết #{threadId}</span>}
    </div>
  );
};

export default Breadcrumb;
