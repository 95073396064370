import React from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import { Favorite, ChatBubble } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "../styles/Home.css"; // CSS riêng cho trang Home
import homeData from "../data/homeMocks.json"; // Dữ liệu mock

// import FeaturesList from "../components/FeaturesList";

const Home = () => {
  return (
    <Box className="home-container">
      {/* <FeaturesList /> */}
      <Box className="news-list">
        {homeData.news.map((newsItem) => (
          <Box key={newsItem.id} className="news-item">
            {/* Header */}
            <Box className="news-header">
              <Avatar
                src="https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100270.jpg"
                alt={newsItem.author}
                className="news-avatar"
              />
              <Box>
                <Typography className="news-author">
                  {newsItem.author}
                </Typography>
                <Typography className="news-date">{newsItem.date}</Typography>
              </Box>
            </Box>
            <Link
              to={`/posts/${newsItem.slug}`}
              className="news-title-link"
              style={{ textDecoration: "none" }}
            >
              {/* Hình ảnh */}
              <Box className="news-image-container">
                <img
                  src={newsItem.images[0]}
                  alt={newsItem.title}
                  className="news-image"
                />
              </Box>

              {/* Content */}
              <Box className="news-content">
                <Typography className="news-title">{newsItem.title}</Typography>

                <Typography className="news-details">
                  {newsItem.shortIntro}
                </Typography>
              </Box>
            </Link>
            {/* Actions */}
            <Box className="news-actions">
              <Box className="news-action-item">
                <IconButton className="action-icon">
                  <Favorite />
                </IconButton>
                <Typography>{newsItem.likes} Thích</Typography>
              </Box>
              <Box className="news-action-item">
                <IconButton className="action-icon">
                  <ChatBubble />
                </IconButton>
                <Typography>{newsItem.commentsCount} Bình luận</Typography>
              </Box>
            </Box>

            {/* Comments */}
            {/* <Box className="news-comments">
              {newsItem.comments.slice(0, 3).map((comment) => (
                <Box key={comment.id} className="comment-item">
                  <Avatar
                    src="https://img.freepik.com/free-photo/view-3d-cool-modern-bird_23-2150946449.jpg"
                    alt={comment.author}
                    className="comment-avatar"
                  />
                  <Box>
                    <Typography className="comment-author">
                      {comment.author}
                    </Typography>
                    <Typography className="comment-text">
                      {comment.text}
                    </Typography>
                  </Box>
                </Box>
              ))}
              <Box sx={{ textAlign: "center", marginTop: "8px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={`/news/${newsItem.id}`}
                  className="discussion-button"
                >
                  Tham gia thảo luận
                </Button>
              </Box>
            </Box> */}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Home;
