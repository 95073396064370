import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/FeatureCard.css";

const FeatureCard = ({ feature }) => {
  return (
    <Box
      className="feature-card"
      sx={{
        backgroundImage: `url(${feature.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "16px",
        height: "300px",
        width: "100%",
        color: feature.textColor || "#fff",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
      }}
    >
      {/* Tiêu đề */}
      <Box sx={{ padding: "16px" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", fontSize: "18px", textTransform: "uppercase" }}
        >
          {feature.subtitle}
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontWeight: "700", fontSize: "24px", lineHeight: "1.2", marginTop: "8px" }}
        >
          {feature.title}
        </Typography>
      </Box>

      {/* Icon Hành động */}
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: "16px",
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          color: "#fff",
        }}
      >
        +
      </Box>
    </Box>
  );
};

export default FeatureCard;
