import React, { useState } from "react";
import "../styles/ImageSlider.css";

const ImageSlider = ({ images, onImageClick }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="image-slider">
      {/* Previous Arrow */}
      <button className="arrow left-arrow" onClick={goToPreviousSlide}>
        <span>&#8249;</span> {/* Mã Unicode cho mũi tên trái */}
      </button>

      {/* Slider Images */}
      <div className="image-slider-wrapper">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slider-item ${index === currentSlide ? "active" : ""}`}
            onClick={() => onImageClick(image)}
            style={{
              transform: `translateX(${(index - currentSlide) * 100}%)`,
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="slider-image"
              onError={(e) => {
                e.target.src =
                  "https://cdn2.fptshop.com.vn/unsafe/1920x0/filters:quality(100)/avatar_nam_anime_10_1b906dde27.jpg";
              }}
            />
          </div>
        ))}
      </div>

      {/* Next Arrow */}
      <button className="arrow right-arrow" onClick={goToNextSlide}>
        <span>&#8250;</span> {/* Mã Unicode cho mũi tên phải */}
      </button>

      <div className="slider-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            className={`indicator ${index === currentSlide ? "active" : ""}`}
            onClick={() => goToSlide(index)}
            aria-label={`Slide ${index + 1}`} /* Thêm hỗ trợ truy cập */
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
