import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close, Edit, Favorite } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import homeData from "../data/homeMocks.json";
import ImageSlider from "../components/ImageSlider"; // Import slider component
// import Comments from "../components/Comments"; // Import comments component
import HighlightHeaderArticle from "../components/HighlighHeaderArticle";

import "../styles/DetailsNews.css";

const DetailsNews = () => {
  const { slug } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  // const [comments, setComments] = useState([]);
  // const [newComment, setNewComment] = useState("");
  const [likes, setLikes] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    // Cuộn lên đầu trang khi vào trang
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const selectedNews = homeData.news.find((item) => item.slug === slug);
    if (selectedNews) {
      setNewsItem(selectedNews);
      // setComments(selectedNews.comments || []);
      setLikes(selectedNews.likes || 0);
      setCommentsCount(selectedNews.commentsCount || 0);
    }
  }, [slug]);

  const handleLikePost = () => {
    setLikes(likes + 1);
  };

  // const handleLikeComment = (commentId) => {
  //     setComments(comments.map((comment) => comment.id === commentId ? {
  //         ...comment,
  //         likes: comment.likes + 1
  //     } : comment));
  // };

  // const handleAddComment = () => {
  //     if (newComment.trim()) {
  //         const newCommentData = {
  //             id: comments.length + 1, author: "Anonymous", text: newComment, likes: 0,
  //         };
  //         setComments([...comments, newCommentData]);
  //         setNewComment("");
  //         setCommentsCount(commentsCount + 1);
  //     }
  // };

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage("");
  };

  if (!newsItem) {
    return <Typography variant="h6">Không tìm thấy bài viết.</Typography>;
  }

  return (
    <>
      <HighlightHeaderArticle
        category={newsItem.category}
        title={newsItem.title}
        description={newsItem.shortIntro}
        author={newsItem.author}
        date={newsItem.date}
        time="AM GMT+7"
        image={newsItem.images[2]}
        bgColor={newsItem.bgColor}
        textColor={newsItem.txtColor}
      />

      <Box className="details-news-container">
        {/* <HighlightHeaderArticle
          category={newsItem.category}
          title={newsItem.title}
          description={newsItem.shortIntro}
          author={newsItem.author}
          date={newsItem.date}
          time="AM GMT+7"
          image={newsItem.images[1]}
          bgColor={newsItem.bgColor}
          textColor={newsItem.txtColor}
        /> */}
        <Grid container spacing={1}>
          {/* Bên trái: Nội dung bài viết */}
          <Grid item xs={12}>
            <Box className="details-news-left">
              <Box className="details-news-header">
                <img
                  src="https://cdn2.fptshop.com.vn/unsafe/1920x0/filters:quality(100)/avatar_nam_anime_10_1b906dde27.jpg"
                  alt="Avatar"
                  className="details-news-avatar"
                />
                <Box>
                  <Typography className="details-news-author">
                    {newsItem.author} - {newsItem.date}
                  </Typography>
                  <Typography className="details-news-role">
                    Đã tồn tại
                  </Typography>
                </Box>
              </Box>
              <Typography className="details-news-title" variant="body1">
                {newsItem.title}
              </Typography>
              <Box className="details-news-slider-wrapper">
                <ImageSlider
                  images={newsItem.images}
                  onImageClick={handleImageClick}
                />
              </Box>
              <Box className="details-news-stats">
                <Box
                  className="details-news-stat-item"
                  onClick={handleLikePost}
                >
                  <Favorite
                    sx={{ color: "red", marginRight: "8px", cursor: "pointer" }}
                  />
                  <Typography>{likes} lượt thích</Typography>
                </Box>
                <Box className="details-news-stat-item">
                  <Edit sx={{ color: "#1782ff", marginRight: "8px" }} />
                  <Typography>{commentsCount} bình luận</Typography>
                </Box>
              </Box>
              <Typography className="details-news-details">
                {newsItem.details.split("\n").map((paragraph, index) => (
                  <p key={index} className="details-news-paragraph">
                    {paragraph}
                  </p>
                ))}
              </Typography>
            </Box>
          </Grid>

          {/*<Grid item xs={12}>*/}
          {/*    <Comments*/}
          {/*        comments={comments}*/}
          {/*        comments={comments}*/}
          {/*        newComment={newComment}*/}
          {/*        setNewComment={setNewComment}*/}
          {/*        handleLikeComment={handleLikeComment}*/}
          {/*        handleAddComment={handleAddComment}*/}
          {/*    />*/}
          {/*</Grid>*/}
        </Grid>

        {/* Popup Full Screen */}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <DialogContent className="dialog-content">
            {/* Close Button */}
            <IconButton
              edge="end"
              className="dialog-close-button"
              onClick={handleClose}
            >
              <Close />
            </IconButton>

            {/* Fullscreen Image */}
            <img
              src={currentImage}
              alt="Full screen"
              className="dialog-image"
            />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default DetailsNews;
