import React from "react";
import "../styles/NoiQuy.css";
import rules from "../data/noiQuy.json"; // Import rules from JSON

const NoiQuy = () => {
  return (
    <div className="noi-quy-container">
      <h1 className="noi-quy-title">{rules.title}</h1>
      <p className="noi-quy-intro">{rules.intro}</p>

      {rules.sections.map((section, index) => (
        <section key={index} className="noi-quy-section">
          <h2 className="section-title">{section.title}</h2>
          <ul className="noi-quy-list">
            {section.content.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        </section>
      ))}
    </div>
  );
};

export default NoiQuy;
