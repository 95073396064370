import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import "../styles/Market.css";
import FeatureSlider from "../components/FeatureSlider";
const categories = [
  "Laptop/Macbook",
  "Máy bàn",
  "Màn hình",
  "Âm thanh",
  "Ánh Sáng",
  "Phụ Kiện",
]; // Danh sách tab

const products = {
  "Laptop/Macbook": [
    {
      id: 1,
      name: "MacBook Pro 14'' and 16''",
      description:
        "M4, M4 Pro, or M4 Max chip. The most advanced Mac laptops for demanding workflows.",
      price: "$1599",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
    {
      id: 2,
      name: "MacBook Air 13'' and 15''",
      description:
        "M2 or M3 chip. Strikingly thin and fast so you can work, play, or create anywhere.",
      price: "$999",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
    {
      id: 1,
      name: "MacBook Pro 14'' and 16''",
      description:
        "M4, M4 Pro, or M4 Max chip. The most advanced Mac laptops for demanding workflows.",
      price: "$1599",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
    {
      id: 2,
      name: "MacBook Air 13'' and 15''",
      description:
        "M2 or M3 chip. Strikingly thin and fast so you can work, play, or create anywhere.",
      price: "$999",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
    {
      id: 1,
      name: "MacBook Pro 14'' and 16''",
      description:
        "M4, M4 Pro, or M4 Max chip. The most advanced Mac laptops for demanding workflows.",
      price: "$1599",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
    {
      id: 2,
      name: "MacBook Air 13'' and 15''",
      description:
        "M2 or M3 chip. Strikingly thin and fast so you can work, play, or create anywhere.",
      price: "$999",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
  ],
  "Máy bàn": [
    {
      id: 1,
      name: "iMac 24''",
      description:
        "Powerful and colorful, with M1 chip for all your desktop needs.",
      price: "$1299",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
  ],
  "Màn hình": [
    {
      id: 1,
      name: "Pro Display XDR",
      description:
        "The ultimate display for professionals, with stunning 6K resolution.",
      price: "$4999",
      image:
        "https://www.apple.com/v/mac/home/cb/images/overview/select/product_tile_mbp_14_16__bkl8zusnkpw2_large.png",
      buttonText: "Chi tiết",
    },
  ],
};

const Market = () => {
  const [activeTab, setActiveTab] = useState("Laptop/Macbook");

  return (
    <Box className="market-container">
      <FeatureSlider />
      {/* Tabs */}
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {categories.map((category) => (
          <Tab
            key={category}
            label={category}
            value={category}
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              color: activeTab === category ? "primary.main" : "text.secondary",
              whiteSpace: "nowrap", // Prevents text wrapping
            }}
          />
        ))}
      </Tabs>

      {/* Product Cards */}
      <Box className="market-products">
        {products[activeTab]?.map((product) => (
          <Card key={product.id} className="market-card">
            <CardMedia
              component="img"
              image={product.image}
              alt={product.name}
            />
            <CardContent>
              <Typography variant="h6" className="market-card-title">
                {product.name}
              </Typography>
              <Typography variant="body2" className="market-card-description">
                {product.description}
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                className="market-card-price"
              >
                {product.price}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, marginTop: 2 }}>
                <Button variant="contained" color="primary">
                  {product.buttonText}
                </Button>
                <Button variant="text">Mua ngay</Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Market;
