import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import detailsData from "../data/details.json";
import commentsData from "../data/comments.json";
import "../styles/ThreadDetails.css";

const Comment = ({ comment }) => (
  <div className="comment-item">
    <div className="comment-content">
      <div className="comment-header">
        <span className="comment-author">{comment.author}</span>
        <span className="comment-date">{comment.date}</span>
      </div>
      <p className="comment-text">{comment.content}</p>
      {comment.replies && comment.replies.length > 0 && (
        <div className="comment-replies">
          {comment.replies.map((reply) => (
            <Comment key={reply.id} comment={reply} />
          ))}
        </div>
      )}
    </div>
  </div>
);

const ThreadDetailsPage = () => {
  const { categoryId, threadId } = useParams();
  const thread = detailsData.details[categoryId]?.find(
    (thread) => thread.id === parseInt(threadId)
  );
  const comments = commentsData.comments[threadId] || [];

  return (
    <div className="thread-details-container">
      <Breadcrumb categoryId={categoryId} threadId={threadId} />

      {/* Phần bài viết */}
      <div className="thread-section">
        <h1 className="thread-title">{thread.title}</h1>
        <p className="thread-meta">
          <span className="author">Tác giả: {thread.author}</span>
          <span className="date">Ngày: {thread.date}</span>
        </p>
        <div className="thread-content">
          <p>
            Đây là nội dung mô phỏng của bài viết. Nội dung đầy đủ sẽ được hiển
            thị tại đây.
          </p>
        </div>
      </div>

      {/* Phần bình luận */}
      <div className="comments-section">
        <h2 className="comments-title">Bình luận</h2>
        {comments.length > 0 ? (
          comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))
        ) : (
          <p>Chưa có bình luận nào.</p>
        )}
      </div>
    </div>
  );
};

export default ThreadDetailsPage;
