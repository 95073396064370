import axios from 'axios';
import { API_BASE_URL } from '../config/constants';
const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers = Object.assign(Object.assign({}, config.headers), { Authorization: `Bearer ${token}` });
    }
    return config;
});
export default apiClient;
