import React from "react";
import { useParams } from "react-router-dom";
import tipsData from "../data/tipsAndTricks.json";
import "../styles/TipDetails.css";

const TipDetails = () => {
  const { tipId } = useParams();
  const tip = tipsData.details[tipId];

  return (
    <div className="tip-details-container">
      <h1 className="tip-details-title">{tip.title}</h1>
      <p className="tip-details-meta">
        By {tip.author} • {tip.date}
      </p>
      <div className="tip-details-content">{tip.content}</div>
    </div>
  );
};

export default TipDetails;
