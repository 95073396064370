// src/pages/Profile.js
import React from 'react';
import {Box, Typography} from '@mui/material';
import "../styles/Profile.css";

const Profile = () => {
    return (
        <Box className="profile-page">
            <Typography variant="h4">Trang Cá Nhân</Typography>
            <Typography variant="body1">Thông tin cá nhân và các hoạt động của bạn.</Typography>
        </Box>
    );
};

export default Profile;
