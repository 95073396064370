import React from "react";
import { Link } from "react-router-dom";
import categoriesData from "../data/categories.json";
import "../styles/Categories.css";

const CategoriesPage = () => (
  <div className="categories-container">
    <h1 className="categories-title">Chuyên Mục</h1>
    <div className="categories-grid">
      {categoriesData.categories.map((category) => (
        <div key={category.id} className="category-item">
          <Link to={`/categories/${category.id}`} className="category-link">
            <h2 className="category-name">{category.name}</h2>
          </Link>
          <p className="category-info">
            Threads: <span>{category.threads}</span> | Messages:{" "}
            <span>{category.messages}</span>
          </p>
        </div>
      ))}
    </div>
  </div>
);

export default CategoriesPage;
